import { useReducer, useState } from "react";
import { combineReducers } from "redux";
import {
  actionHandlers,
  getIntersectionPoint,
  normalizeFq,
} from "../../helpers";
import Map from "../parts/Map";
import FqBar from "../parts/FqBar";
import formatDistance from "date-fns/formatDistance";
import ukLocale from "date-fns/locale/uk";
import DataLoader from "./CSVDataLoader";
import formatDate from "date-fns/format";

const ACTIONS = {
  INITIATE: "INITIATE",
};

const REDUCER = combineReducers({
  objects: actionHandlers({}),
  center: () => null,
  zoom: () => null,
});
const DEFAULT = {
  objects: [],
  center: { x: 52.0, y: 61.0 },
  zoom: 7,
  data: {},
};

const HOMES = {
  house: {
    location: { lat: 48.9016871, lng: 37.9454185 },
    color: "orangered",
  },
  forest: {
    location: {
      lat: 48.9490865,
      lng: 37.8300248,
    },
    color: "aqua",
  },
};

const pelengsToVectors = (pelengs, fq) => {
  const now = new Date();
  const vectorsWithIntersection = pelengs.filter(
    ({ locHouse, locForest }) => locHouse && locForest
  );

  // let opacity = 1;
  //
  // return vectorsWithIntersection
  //   .sort(({ time: time1 }, { time: time2 }) => {
  //     const t1 = time1.valueOf();
  //     const t2 = time2.valueOf();
  //     return t1 === t2 ? 0 : t1 > t2 ? 1 : -1;
  //   })
  //   .reduce((acc, { locHouse, locForest, time, uuid }, i) => {
  //     if (locHouse && locForest) {
  //       const houseConfig = HOMES.house;
  //       const forestConfig = HOMES.forest;
  //
  //       const intersection = getIntersectionPoint(
  //         houseConfig.location,
  //         locHouse,
  //         forestConfig.location,
  //         locForest
  //       );
  //
  //       acc.push({
  //         key: uuid,
  //         type: "point",
  //         label: formatDistance(time, now, {
  //           locale: ukLocale,
  //         }),
  //         value: intersection,
  //         icon: "TARGET",
  //         opacity,
  //       });
  //     }
  //
  //     opacity -= 0.5 / vectorsWithIntersection.length;
  //
  //     return acc;
  //   }, []);
  return pelengs.reduce((acc, { locHouse, locForest, time, uuid }, i) => {
    [locHouse, locForest]
      .filter((x) => typeof x === "number")
      .forEach((azimuth) => {
        const homeConfig = azimuth === locHouse ? HOMES.house : HOMES.forest;
        acc.push({
          // key: [
          //   fq,
          //   azimuth,
          //   JSON.stringify(homeConfig.location),
          //   time.valueOf(),
          // ].join("/"),
          key: uuid + JSON.stringify(homeConfig.location),
          // key: uuidv4(),
          type: "vector",
          label: `${normalizeFq(fq)} ${formatDate(time, "dd.MM HH:mm")}`,
          value: homeConfig.location,
          color: homeConfig.color,
          azimuth,
        });
      });
    return acc;
  }, []);
};

const CSVViewer = ({ data }) => {
  const [{ zoom }, dispatch] = useReducer(REDUCER, {
    ...DEFAULT,
    data: data,
  });
  const [fqOpen, setFqOpen] = useState(null);
  const objects =
    fqOpen == null
      ? []
      : pelengsToVectors(data.find((x) => x.fq === fqOpen).pelengs, fqOpen);
  // if (objects.length !== _.uniqBy(objects, "key").length) {
  //   console.log(objects.map(({ key }) => key));
  // }
  let center = HOMES.house.location;
  if (objects.length) {
    center = objects[0].value;
  }

  console.log(objects);

  return (
    <>
      <div className="app__sidebar">
        <FqBar data={data} setFqOpen={setFqOpen} fqOpen={fqOpen} />
      </div>
      <div className="app__map">
        <Map objects={objects} center={center} zoom={zoom} />
      </div>
    </>
  );
};

export default () => (
  <DataLoader>
    <CSVViewer />
  </DataLoader>
);

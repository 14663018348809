import { actionHandlers } from "../helpers";
import _ from "lodash";
const INITIAL = {
  email: null,
  uid: null,
  team: null,
  loaded: false,
};

export default actionHandlers(
  {
    USER_AUTH_SUCCESS: (state, payload) => {
      return {
        ...payload,
        loaded: true,
      };
    },
    USER_LOGOUT_SUCCESS: (state, payload) => {
      return {
        ...INITIAL,
        loaded: true,
      };
    },
    USER_AUTH_EMPTY: (state, payload) => {
      return {
        ...INITIAL,
        loaded: true,
      };
    },
    HARDWARE_UPDATE: (state, hardwares) => {
      hardwares = _.toArray(hardwares);
      hardwares = hardwares.filter(({ id }) => {
        return id && !state.hardwares.some((h) => h.id === id);
      });
      if (hardwares.length === 0) return state;
      return {
        ...state,
        hardwares: [...state.hardwares, ...hardwares],
      };
    },
    HARDWARE_REMOVE: (state, hardwares_ids) => {
      return {
        ...state,
        hardwares: state.hardwares.filter(({ id }) => {
          return !hardwares_ids.includes(id);
        }),
      };
    },
  },
  INITIAL
);

import React, { useState } from "react";
import { Paper, Typography } from "@mui/material";
// import HardwareSettings from "./arch/HardwareSettings";
// import TeamSettings from "./arch/TeamSettings";
// import SomethingSettings from "./arch/SomethingSettings";
import HardwareCard from "./HardwareCard";
import { useSelector } from "react-redux";
import Button from "@mui/material/Button";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase";

// const pageToComponent = {
//   hardware: HardwareSettings,
//   team: TeamSettings,
//   something: SomethingSettings,
// };

const Settings = () => {
  const [page, setPage] = useState("hardware");

  // const PageComponent = pageToComponent[page];

  return (
    <Paper
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        padding: "10px 20px",
        maxWidth: "1200px",
        minHeight: "100vh",
        margin: "0 auto",
      }}
      elevation={3}
    >
      <Typography
        variant="h4"
        style={{
          textAlign: "center",
          marginBottom: "15px",
          marginTop: "30px",
        }}
      >
        Налаштування
      </Typography>
      {/*      <ToggleButtonGroup
        fullWidth={true}
        size="medium"
        color="secondary"
        style={{ margin: "0 auto" }}
        value={page}
        exclusive
        onChange={(e) => setPage(e.target.value)}
      >
        <ToggleButton value="hardware" aria-label="left aligned">
          Обладнання
        </ToggleButton>
        <ToggleButton value="team" aria-label="centered">
          Команда
        </ToggleButton>
        <ToggleButton value="something" aria-label="right aligned">
          Шось ще
        </ToggleButton>
      </ToggleButtonGroup>
      <PageComponent />*/}

      <Hardwares />
      <div style={{ marginTop: 50, textAlign: "center" }}>
        <Button
          onClick={async () => {
            if (window.confirm("Підтвердіть що бажаєте вийти з аккаунту")) {
              await signOut(auth);
              window.reload();
            }
          }}
          variant={"outlined"}
        >
          Вийти з аккаунту
        </Button>
      </div>
    </Paper>
  );
};

const Hardwares = () => {
  const [openAdd, setOpenAdd] = useState(false);
  const hardwares = useSelector(({ profile }) => profile.hardwares);

  const onChangeSave = (id, newConfig) => {
    console.log("onChangeSave", id, newConfig);
  };
  const onAddSave = (newConfig) => {
    setOpenAdd(false);
  };
  return (
    <div
      className="st-hardwares"
      style={{ backgroundColor: "#292929", padding: 20, borderRadius: 5 }}
    >
      {hardwares.map((config, i) => (
        <HardwareCard
          key={config.id}
          config={config}
          onChangeSave={onChangeSave}
          onAddSave={onAddSave}
        />
      ))}
      {openAdd && (
        <HardwareCard add onChangeSave={onChangeSave} onAddSave={onAddSave} />
      )}
      <div style={{ marginTop: "20px", textAlign: "center" }}>
        <Button onClick={() => setOpenAdd(true)} variant={"outlined"}>
          Додати обладнання
        </Button>
      </div>
    </div>
  );
};

export default Settings;

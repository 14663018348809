import { initializeApp } from "firebase/app";
import {
  doc,
  getDoc,
  getFirestore,
  onSnapshot,
  getDocs,
  collection,
  query,
  where,
} from "firebase/firestore";
import { getAuth, signOut } from "firebase/auth";
import _ from "lodash";
import store from "./store";
import { groupChangesFromCollectionSnapshot } from "./helpers";

// --------------------------------------------------------------
const USE_TEST_DB = true;
// --------------------------------------------------------------

const firebaseConfigTest = {
  apiKey: "AIzaSyB2UtnUWYh4O4k0mQ82zzqYBZdL-ecuGDc",
  authDomain: "vuho-pelengs-dev.firebaseapp.com",
  projectId: "vuho-pelengs-dev",
  storageBucket: "vuho-pelengs-dev.appspot.com",
  messagingSenderId: "534450159139",
  appId: "1:534450159139:web:53df6ac78475658d78bb96",
};
const firebaseConfig = {
  apiKey: "AIzaSyC6RcmKbFpGFHwmSSYBPtysW1Qapem7Rts",
  authDomain: "vuho-pelengs.firebaseapp.com",
  projectId: "vuho-pelengs",
  storageBucket: "vuho-pelengs.appspot.com",
  messagingSenderId: "614594849027",
  appId: "1:614594849027:web:e87deaf6d4850bc113a136",
};

let fConfig = firebaseConfigTest;
// let fConfig = firebaseConfig;

if (window.location.hostname === "de.sapiense.com") {
  fConfig = firebaseConfig;
}

// Initialize Firebase
const app = initializeApp(fConfig);

export const db = getFirestore(app);

export const auth = getAuth();

window.__firestore = {
  app,
  db,
  auth,
};

// --------------------------------------------------------------

export const startListeningSnapshots = ({ profileData }) => {
  // onSnapshot(query(collection(db, "pelengs")), (pelengs) => {
  //   dispatch({
  //     type: "NAMES_SNAPSHOT",
  //     payload: _.pickBy(names.data(), (value, key) => value !== "") || {},
  //   });
  // });
  onSnapshot(collection(db, "pelengs"), (input) => {
    const { toUpdate, toRemove } = groupChangesFromCollectionSnapshot(input);

    if (!_.isEmpty(toUpdate)) {
      store.dispatch({
        type: "PELENGS_UPDATE",
        payload: toUpdate,
      });
    } else {
      store.dispatch({
        type: "PELENGS_UPDATE",
        payload: {},
      });
    }
    if (!_.isEmpty(toRemove)) {
      store.dispatch({
        type: "PELENGS_REMOVE",
        payload: _.keys(toRemove),
      });
    }
  });
  onSnapshot(
    query(
      collection(db, "hardware"),
      where("team_id", "==", profileData.team.id)
    ),
    (input) => {
      const { toUpdate, toRemove } = groupChangesFromCollectionSnapshot(input);

      if (!_.isEmpty(toUpdate)) {
        store.dispatch({
          type: "HARDWARE_UPDATE",
          payload: toUpdate,
        });
      }
      if (!_.isEmpty(toRemove)) {
        store.dispatch({
          type: "HARDWARE_REMOVE",
          payload: _.keys(toRemove),
        });
      }
    }
  );
};

auth.onAuthStateChanged(async (user) => {
  if (user) {
    let profileData = {
      uid: user.uid,
      email: user.email,
    };
    // --------------------------------------------------------------
    let extra = {},
      team = {};
    try {
      extra = await getDoc(doc(db, "users", user.uid));
      // console.log({ extra });
      team = await getDoc(doc(db, "teams", extra.data().team_id));
      // console.log({ team });
    } catch (err) {
      // console.log({ err });
      alert(
        "Помилка доступу. Зверніться до адміністратору щоб вам надали права"
      );
      store.dispatch({
        type: "USER_AUTH_ERROR",
        source: "on getting user-doc from firestore",
      });
      await signOut(auth);
      window.location.reload();
      return;
    }
    profileData = {
      ...profileData,
      ..._.omit(extra.data(), ["id", "team_id"]),
      team: team.data(),
    };

    // --------------------------------------------------------------
    let hardwares = [];
    try {
      hardwares = await getDocs(
        query(
          collection(db, "hardware"),
          where("team_id", "==", profileData.team.id)
        )
      );
      hardwares = hardwares.docs.map((d, id) => ({
        ...d.data(),
        id: d.ref.id,
      }));
    } catch (err) {
      console.log("no hardware detected");
    }
    profileData = {
      ...profileData,
      hardwares,
    };
    // --------------------------------------------------------------

    store.dispatch({
      type: "USER_AUTH_SUCCESS",
      payload: profileData,
    });
    // --------------------------------------------------------------
    startListeningSnapshots({ profileData });
  } else {
    store.dispatch({
      type: "USER_AUTH_EMPTY",
      source: "no user in firestore",
    });
  }
});

import { useSelector } from "react-redux";
import _ from "lodash";

export const useAuth = () => {
  return useSelector(({ profile }) => {
    return profile.email && profile.uid && profile.team && profile.hardwares;
  });
};
export const useActiveHardwares = () => {
  return useSelector(({ profile }) => {
    return profile.hardwares.filter(({ current_location, locations }) => {
      if (!_.isPlainObject(locations) || _.isEmpty(locations)) return false;
      const current = locations[current_location];
      return (
        typeof current.nickname === "string" &&
        current.nickname.length >= 1 &&
        typeof current.lat === "number" &&
        typeof current.lng === "number"
      );
    });
  }, _.isEqual);
};

export const useAbleToAdd = () => {
  const hasHardware = useActiveHardwares().length > 0;
  const isRole = useSelector(
    ({ profile }) => profile.role === "admin" || profile.role === "operator"
  );
  return hasHardware && isRole;
};

import { createBrowserRouter, createHashRouter } from "react-router-dom";

import Wrapper from "./UI/Wrapper.js";
import App from "./UI/App.js";
import CSVViewer from "./UI/CSV/CSVViewer.js";
import { isInIframe } from "./helpers";
import Settings from "./UI/Settings/Settings";
import Add from "./UI/Add/Add";

const createRouter = isInIframe ? createHashRouter : createBrowserRouter;

const router = createRouter([
  {},
  {
    path: "/",
    element: <Wrapper />,
    children: [
      {
        path: "/",
        element: <App />,
      },
      {
        path: "/csv",
        element: <CSVViewer />,
      },
      {
        path: "/settings",
        element: <Settings />,
      },
      {
        path: "/add",
        element: <Add />,
      },
    ],
  },
]);

export default router;

import { useState } from "react";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Skeleton from "@mui/material/Skeleton";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { auth } from "../firebase";
import { useSelector } from "react-redux";

const Authenticator = () => {
  const loaded = useSelector(({ profile }) => profile.loaded);

  const [opened, setOpened] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const authRequest = async () => {
    try {
      const { user } = await signInWithEmailAndPassword(auth, email, password);
      console.log("SUCCESS AUTH", user);
    } catch (err) {
      const errorCode = err.code;
      setError(err.message);
    }
  };

  if (!loaded) {
    return (
      <>
        <div
          className="container"
          style={{ display: "flex", justifyContent: "center", marginTop: 50 }}
        >
          <Skeleton variant="circular" width={200} height={200} />
        </div>
        <div
          className="container"
          style={{ display: "flex", justifyContent: "center", marginTop: 10 }}
        >
          <Typography>Перевірка прав доступу</Typography>
        </div>
      </>
    );
  }

  return (
    <>
      <Dialog open={opened} onClose={() => setOpened(false)}>
        <DialogTitle>Авторизація</DialogTitle>
        <form action="src/ui/parts/Auth" onSubmit={authRequest}>
          <DialogContent>
            <DialogContentText>
              Для додаткових функцій необхідно авторизуватися
            </DialogContentText>
            {error && <Alert severity="error">{error}</Alert>}
            <TextField
              autoFocus
              margin="dense"
              id="email"
              label="Email адреса"
              type="email"
              fullWidth
              variant="standard"
              value={email}
              onChange={({ target }) => setEmail(target.value)}
            />
            <TextField
              autoFocus
              margin="dense"
              id="pass"
              label="Пароль"
              type="password"
              fullWidth
              variant="standard"
              value={password}
              onChange={({ target }) => setPassword(target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpened(false)}>Відмінити</Button>
            <Button onClick={authRequest} variant={"outlined"}>
              Авторизуватись
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <div className={"container"}>
        <div style={{ marginTop: 50 }}>
          Доступ обмеженний,{" "}
          <Link href="#" underline="hover" onClick={() => setOpened(true)}>
            увійдіть
          </Link>{" "}
          в свій аккаунт для роботи з программою
        </div>
      </div>
    </>
  );
};

export default Authenticator;

import { normalizeFq } from "../../helpers";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import formatDate from "date-fns/format";
import ukLocale from "date-fns/locale/uk";
import _ from "lodash";
import Distance from "./Distance";
import { useSelector } from "react-redux";
import copy from "copy-to-clipboard";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useState } from "react";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useAbleToAdd } from "../../hooks";
import { TextField } from "@mui/material";

const FqBar = ({ pelengs, setFqOpen, fqOpen, setPelengSelected }) => {
  const pelegsDownloaded = useSelector(({ flags }) => flags.pelegsDownloaded);
  const isAbleToAdd = useAbleToAdd();
  const navigate = useNavigate();
  const [query, setQuery] = useState("");

  const onSearchChange = ({ target }) => {
    const newValue = target.value
      .trim()
      .replace(",", ".")
      .replace("/", ".")
      .replace("ю", ".")
      .replace("б", ".")
      .replace(":", ".")
      .replace("?", ".")
      .replace(/[^0-9\.]/g, "");

    setQuery(newValue);
  };

  const data = pelengs.reduce((acc, p) => {
    if (acc[p.fq]) {
      acc[p.fq].pelengs.push(p);
    } else {
      acc[p.fq] = {
        fq: p.fq,
        pelengs: [p],
      };
    }
    return acc;
  }, {});
  const sortedAndFiltered = _.toArray(data)
    .sort((a, b) => a.fq - b.fq)
    .filter(({ fq }) => {
      if (!query) return true;
      const fqStr = normalizeFq(fq);
      return (
        fqStr.indexOf(query) !== -1 ||
        fqStr.replace(".", "").indexOf(query) !== -1
      );
    });
  return (
    <div className={"fqbar"}>
      <div className="freq-sidebar">
        <div className="freq-sidebar-search">
          <TextField
            variant="filled"
            value={query}
            onChange={onSearchChange}
            label="Пошук по частоті"
            type={"search"}
            autoComplete={"off"}
          />
        </div>
        {/*<div className="freq-sidebar-list__empty"></div>*/}
      </div>
      <div className="fqbar__content">
        {sortedAndFiltered.map(({ fq, pelengs }) => {
          const closestTime = _.maxBy(pelengs, "created_at").created_at;
          return (
            <Accordion
              key={fq}
              elevation={3}
              square
              sx={{
                "&.Mui-expanded": { marginTop: "8px", marginBottom: "8px" },
              }}
              expanded={fqOpen === fq}
              onChange={(ev, expand) => {
                setFqOpen(expand ? fq : null);
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={summaryStyles}
                // disableGutters
              >
                <div style={{ flexDirection: "column", display: "flex" }}>
                  <Typography>{normalizeFq(fq)}</Typography>
                  <Typography
                    sx={{ color: "text.secondary", fontSize: 9, lineHeight: 1 }}
                  >
                    {/*{String(closestTime)}*/}
                    {pelengs.length} од, <Distance value={closestTime} />
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails sx={detailsStyles}>
                <div style={{ padding: "0 16px" }}>
                  {_(pelengs)
                    .groupBy(({ created_at }) =>
                      formatDate(created_at, "cccc, dd.MM", {
                        locale: ukLocale,
                      })
                    )
                    .map((list, dayName) => {
                      return (
                        <div key={dayName} className={"fqbar__peleng-group"}>
                          <Typography sx={{ fontWeight: "bold" }}>
                            {dayName}
                          </Typography>
                          {list.map((peleng, i) => {
                            const {
                              created_at,
                              directions,
                              lat,
                              lng,
                              radius,
                              id,
                            } = peleng;
                            return (
                              <div
                                className="fqbar__peleng"
                                key={id}
                                onClick={() => console.log(peleng)}
                                onMouseEnter={() => setPelengSelected(id)}
                                onMouseLeave={() => setPelengSelected(null)}
                              >
                                <div
                                  className="fqbar__peleng-time"
                                  title={"час"}
                                >
                                  <Typography>
                                    {formatDate(created_at, "HH:mm")}
                                  </Typography>
                                </div>
                                <div
                                  className="fqbar__peleng-additional"
                                  title={"ширина, довгота"}
                                  onClick={(ev) => {
                                    copy([lat, lng].join(", "));
                                    ev.stopPropagation();
                                  }}
                                >
                                  <Typography>
                                    {[lat, lng]
                                      .map((n) => n.toFixed(2))
                                      .join(", ")}
                                  </Typography>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      );
                    })
                    .value()}
                </div>
              </AccordionDetails>
            </Accordion>
          );
        })}
        {pelegsDownloaded && sortedAndFiltered.length === 0 && (
          <div className="fqbar__empty">Немає пеленгів =(</div>
        )}
        {!pelegsDownloaded && (
          <div className="fqbar__loading">
            <CircularProgress />
          </div>
        )}
      </div>
      {isAbleToAdd && (
        <div className="fqbar__bottom">
          <Button
            onClick={() => navigate("/add")}
            variant={"contained"}
            sx={{ width: "100%", borderRadius: 0 }}
          >
            Додати
          </Button>
        </div>
      )}
    </div>
  );
};
const summaryStyles = {
  backgroundColor: "rgba(255, 255, 255, .05)",
  minHeight: 20,
  "&.Mui-expanded": {
    minHeight: 20,
  },
  "&.Mui-expanded .MuiAccordionSummary-content": {
    marginTop: "8px",
    marginBottom: "8px",
  },
  ".MuiAccordionSummary-content": {
    marginTop: "2px",
    marginBottom: "2px",
  },
};
const detailsStyles = {
  paddingLeft: 0,
  paddingRight: 0,
  marginTop: "4px",
  marginBottom: "4px",
};

export default FqBar;

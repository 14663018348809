import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "@forevolve/bootstrap-dark/dist/css/bootstrap-dark.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { blueGrey, deepOrange } from "@mui/material/colors";
import ukLocale from "date-fns/locale/uk";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import router from "./router";
import { RouterProvider } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
import store from "./store";
import "./firebase";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <>
    <ThemeProvider
      theme={createTheme({
        palette: {
          mode: "dark",
          primary: deepOrange,
          secondary: blueGrey,
        },
        typography: {
          fontSize: 11,
        },
      })}
    >
      <LocalizationProvider
        adapterLocale={ukLocale}
        dateAdapter={AdapterDateFns}
      >
        <ReduxProvider store={store}>
          <RouterProvider router={router} />
        </ReduxProvider>
      </LocalizationProvider>
    </ThemeProvider>
  </>
);

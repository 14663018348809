import _ from "lodash";
import LatLon from "geodesy/latlon-spherical";

export const actionHandlers = (rules, initial) => (state, action) => {
  if (rules[action.type]) return rules[action.type](state, action.payload);
  return state || initial;
};
// --------------------------------------------------------------
const fqCache = {};
export const normalizeFq = (fq) => {
  if (typeof fq !== "number") return "[not recognized]";
  if (fqCache[fq]) return fqCache[fq];

  const str = String(fq);
  if (str.indexOf(".") !== -1 && str.split(".")[1].length > 3) {
    return (fqCache[fq] = str);
  }

  return (fqCache[fq] = parseFloat(fq).toFixed(3));
};

// --------------------------------------------------------------
export const getClosestPeleng = (pelengs) => {
  // console.log(
  //   pelengs,
  //   _.maxBy(pelengs, ({ time }) => time.valueOf())
  // );

  return _.maxBy(pelengs, ({ time }) => time.valueOf());
};

// --------------------------------------------------------------
export const groupChangesFromCollectionSnapshot = (input) => {
  const changes = input.docChanges();

  const toUpdate = changes
    .filter(({ type }) => type === "added" || type === "modified")
    .reduce((acc, { doc }) => ({ ...acc, [doc.id]: doc.data() }), {});

  const toRemove = changes
    .filter(({ type }) => type === "removed")
    .reduce((acc, { doc }) => ({ ...acc, [doc.id]: doc.data() }), {});
  return {
    toUpdate,
    toRemove,
  };
};
// --------------------------------------------------------------

export const getIntersectionPoint = (point1, bearing1, point2, bearing2) => {
  console.log(LatLon);
  const firstPoint = new LatLon(point1.lat, point1.lng);
  const secondPoint = new LatLon(point2.lat, point2.lng);

  const { _lat, _lon } = LatLon.intersection(
    firstPoint,
    bearing1,
    secondPoint,
    bearing2
  );

  return {
    lat: _lat,
    lng: _lon,
  };
};
// --------------------------------------------------------------
function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}
export const isInIframe = inIframe();

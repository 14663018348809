import { applyMiddleware, combineReducers, createStore } from "redux";
import { createLogger } from "redux-logger";
import profileReducer from "./reducers/profile";
import { actionHandlers } from "./helpers";
import _ from "lodash";
const logger = createLogger({
  // diff: __DEV__,
  collapsed: true,
});

const store = createStore(
  combineReducers({
    profile: profileReducer,
    flags: actionHandlers(
      {
        PELENGS_UPDATE: (state) => ({
          ...state,
          pelegsDownloaded: true,
        }),
      },
      {
        pelegsDownloaded: false,
      }
    ),
    pelengs: actionHandlers(
      {
        PELENGS_UPDATE: (state, payload) => {
          return {
            ...state,
            ..._.mapValues(payload, (p, id) => {
              return {
                id,
                ...p,
                created_at: p.created_at.seconds
                  ? p.created_at.seconds * 1000
                  : p.created_at,
              };
            }),
          };
        },
      },
      {}
    ),
  }),
  applyMiddleware(logger)
);

window.store = store;
window._ = _;

export default store;

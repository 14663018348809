import FqBar from "../parts/FqBar";
import Map from "../parts/Map";
import * as React from "react";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import _ from "lodash";
import { useActiveHardwares } from "../../hooks";
import formatISO from "date-fns/formatISO";
import { addDoc, collection, doc, setDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { v4 as uuidv4 } from "uuid";
const NewPelengForm = () => {
  return (
    <form
      style={{
        display: "flex",
        flexDirection: "column",
        rowGap: "8px",
      }}
    >
      <TextField
        type="number"
        style={{ margin: "0 4px" }}
        size="small"
        required
        label="latitude (ширина)"
        value={""}
      />
      <TextField
        type="number"
        style={{ margin: "0 4px" }}
        size="small"
        required
        label="longitude (довгота)"
        value={""}
      />
      <TextField
        type="number"
        style={{ margin: "0 4px" }}
        size="small"
        required
        label="частота"
        value={""}
      />
    </form>
  );
};

const NewDirectionForm = () => {};

const tabToFormComponent = {
  peleng: NewPelengForm,
  direction: NewDirectionForm,
};

const Add = () => {
  const [activeTab, setActiveTab] = useState("direction");
  const team_id = useSelector(({ profile }) => profile.team.id);
  const uid = useSelector(({ profile }) => profile.uid);
  const [fq, setFq] = useState("");
  const [azimuth, setAzimuth] = useState("");
  const navigate = useNavigate();

  const hardwares = useActiveHardwares();

  const options = useSelector(({ profile }) => {
    return hardwares.map(
      ({ id, current_location, locations, nickname, color }) => {
        const current = locations[current_location];
        return {
          lat: current.lat,
          lng: current.lng,
          hardware_id: id,
          height: current.height,
          location_id: current.id,
          displayName: `${current.nickname} (${nickname})`,
          displayColor: color,
        };
      }
    );
  }, _.isEqual);

  const [activeOption, setActiveOption] = useState(options[0].hardware_id);
  const currentHardware = options.find(
    ({ hardware_id }) => hardware_id === activeOption
  );

  const fqUnvalid = fq > 6000 || fq < 0;
  const azimuthUnvalid = azimuth > 360 || azimuth < 0;

  const isReadyToSend =
    typeof fq === "number" &&
    !fqUnvalid &&
    typeof azimuth === "number" &&
    !azimuthUnvalid;

  const saveRequest = async () => {
    const newId = uuidv4();
    const payload = {
      id: newId,
      auto: false,
      azimuth,
      confidence: 0,
      created_at: new Date().valueOf(),
      created_by: uid,
      fq,
      hardware_id: currentHardware.hardware_id,
      height: currentHardware.height,
      lat: currentHardware.lat,
      lng: currentHardware.lng,
      notes: "",
      location_id: currentHardware.location_id,
      signal_power: 0,
      team_id: team_id,
      time: formatISO(new Date().valueOf()),
    };
    console.log("SAVE", payload);

    // return;
    const ref = await setDoc(doc(db, "directions", newId), payload);
    // await setDoc(
    //   doc(db, "directions", ref.id),
    //   { id: ref.id },
    //   { merge: true }
    // );
    console.log("SAVE success");
    alert("Створенно успішно!");
    setFq("");
    setAzimuth("");
  };

  const mapObjects = isReadyToSend
    ? [
        // {
        //   key: currentHardware.hardware_id,
        //   type: "point",
        //   value: {
        //     lat: currentHardware.lat,
        //     lng: currentHardware.lng,
        //   },
        //   color: currentHardware.displayColor,
        // },
        {
          key: activeOption + fq + azimuth,
          type: "vector",
          value: {
            lat: currentHardware.lat,
            lng: currentHardware.lng,
          },
          azimuth,
          label: `${fq}`,
          color: currentHardware.displayColor,
        },
      ]
    : [
        {
          key: currentHardware.hardware_id,
          type: "point",
          value: {
            lat: currentHardware.lat,
            lng: currentHardware.lng,
          },
          color: currentHardware.displayColor,
        },
      ];

  return (
    <>
      <div className="app__sidebar">
        <div className="fqbar">
          <div className="fqbar__content">
            <Typography
              style={{
                textAlign: "center",
                position: "relative",
                height: "35px",
                lineHeight: "35px",
              }}
              variant="h5"
            >
              <div style={{ position: "absolute", top: 0, left: 0 }}>
                <IconButton onClick={() => navigate(-1)} title="назад">
                  <ArrowBackIcon />
                </IconButton>
              </div>
              Додати
            </Typography>
            <div style={{ margin: "8px 4px" }}>
              <ToggleButtonGroup
                fullWidth={true}
                exclusive
                value={activeTab}
                size="small"
              >
                <ToggleButton
                  value="direction"
                  onClick={() => setActiveTab("direction")}
                >
                  Напрямок
                </ToggleButton>
                <ToggleButton
                  value="peleng"
                  onClick={() => setActiveTab("peleng")}
                  disabled={true}
                >
                  Пеленг
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
            <>
              <form
                style={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "8px",
                }}
              >
                <label>
                  <Typography style={{ margin: "0 6px" }} variant="body1">
                    Пристрій
                  </Typography>
                  <Select
                    style={{ margin: "0 4px" }}
                    size="small"
                    value={activeOption}
                    onChange={({ target }) => {
                      setActiveOption(target.value);
                    }}
                  >
                    {options.map(({ hardware_id, displayName }) => (
                      <MenuItem key={hardware_id} value={hardware_id}>
                        {displayName}
                      </MenuItem>
                    ))}
                  </Select>
                </label>
                <TextField
                  name={"fq"}
                  type="number"
                  style={{ margin: "0 4px" }}
                  size="small"
                  required
                  label="Частота"
                  error={fqUnvalid}
                  helperText={fqUnvalid ? "Некорректна частота" : null}
                  value={fq}
                  onChange={({ target }) => {
                    setFq(parseFloat(target.value));
                  }}
                />
                <TextField
                  type="number"
                  style={{ margin: "0 4px" }}
                  size="small"
                  required
                  label="Азимут"
                  value={azimuth}
                  error={azimuthUnvalid}
                  helperText={azimuthUnvalid ? "Некорректний азимут" : null}
                  onChange={({ target }) => {
                    setAzimuth(parseFloat(target.value));
                  }}
                  autoComplete={"off"}
                />
              </form>
            </>
          </div>
          <div className="fqbar__bottom"></div>
          <Button
            sx={{ borderRadius: 0 }}
            variant="contained"
            disabled={!isReadyToSend}
            onClick={saveRequest}
          >
            Зберегти
          </Button>
        </div>
      </div>
      <div className="app__map">
        <Map
          objects={mapObjects}
          center={mapObjects.length > 0 ? mapObjects[0].value : null}
          zoom={null}
        />
      </div>
    </>
  );
};
export default Add;

import _ from "lodash";
import Radio from "@mui/material/Radio";
import React from "react";
import { TextField } from "@mui/material";

const Locations = ({
  locations,
  current_location,
  setLocations,
  setCurrentPoint,
  dbLocations,
}) => {
  return _.toArray(locations)
    .sort((a, b) => b.created_at - a.created_at)
    .map(({ lat, lng, height, nickname, id }) => {
      const isInDb = dbLocations.includes(id);
      return (
        <div className="st-locations__loc" key={id}>
          <div className="st-locations__radio">
            <Radio
              checked={current_location === id}
              onChange={() => setCurrentPoint(id)}
              value={id}
            />
          </div>
          <SettingsField
            label={"Кодова назва локації"}
            value={nickname}
            onChange={({ target }) => {
              setLocations({
                ...locations,
                [id]: {
                  ...locations[id],
                  nickname: target.value,
                },
              });
            }}
          />
          <SettingsField
            label={"latitude"}
            value={lat}
            type={"number"}
            disabled={isInDb}
            onChange={({ target }) => {
              setLocations({
                ...locations,
                [id]: {
                  ...locations[id],
                  lat: target.value,
                },
              });
            }}
          />
          <SettingsField
            label={"longitude"}
            disabled={isInDb}
            value={lng}
            type={"number"}
            onChange={({ target }) => {
              setLocations({
                ...locations,
                [id]: {
                  ...locations[id],
                  lng: target.value,
                },
              });
            }}
          />
          <SettingsField
            label={"Висота"}
            width={85}
            type={"number"}
            value={height}
            onChange={({ target }) => {
              setLocations({
                ...locations,
                [id]: {
                  ...locations[id],
                  height: parseFloat(target.value.replace(",", ".")),
                },
              });
            }}
          />
        </div>
      );
    });
};

const SettingsField = (props) => {
  return (
    <TextField
      variant="outlined"
      size={"small"}
      InputLabelProps={{ shrink: true }}
      sx={{ width: props.width || "auto", marginBottom: 1 }}
      type={"text"}
      {...props}
    />
  );
};

export default Locations;

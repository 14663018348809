import FqBar from "./parts/FqBar";
import Map from "./parts/Map";
import * as React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import ukLocale from "date-fns/locale/uk";
import formatDistance from "date-fns/formatDistance";

const App = () => {
  const [fqOpen, setFqOpen] = useState(null);
  const [defaultCenter, setDefautCenter] = useState({
    lat: 48.596025465261775,
    lng: 38.000113327997255,
  });
  const [pelengSelected, setPelengSelected] = useState(null);
  const pelengs = _.toArray(useSelector(({ pelengs }) => pelengs));

  const mapObjects = pelengs
    .filter(({ fq }) => fq === fqOpen)
    .sort((a, b) => a.created_at - b.created_at)
    .map(({ created_at, directions, lat, lng, radius, id }) => {
      const d = {
        key: id,
        type: "point",
        // type: "circle",
        // icon: "TARGET",
        value: {
          lat,
          lng,
        },
        label: formatDistance(created_at, new Date(), {
          locale: ukLocale,
        }),
        opacity: pelengSelected ? (pelengSelected === id ? 1 : 0.4) : 1,
        // radius: radius,
      };
      return d;
    });

  let center = defaultCenter;
  if (mapObjects.length) {
    center = pelengSelected
      ? mapObjects.find(({ key }) => key === pelengSelected).value
      : mapObjects[0].value;
  }
  const zoom = 14;

  return (
    <>
      <div className="app__sidebar">
        <FqBar
          pelengs={pelengs}
          setFqOpen={setFqOpen}
          fqOpen={fqOpen}
          setPelengSelected={setPelengSelected}
        />
      </div>
      <div className="app__map">
        <Map objects={mapObjects} center={center} zoom={zoom} />
      </div>
    </>
  );
};

export default App;

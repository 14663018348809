import React, { useEffect, useState } from "react";
import Papa from "papaparse";
import _ from "lodash";
import isValidDate from "date-fns/isValid";
import parseDate from "date-fns/parse";
import add from "date-fns/add";
import { v4 as uuidv4 } from "uuid";
import { getClosestPeleng } from "../../helpers";

const DataLoader = ({ children }) => {
  const [data, setData] = useState(null);
  useEffect(() => {
    fetch("./pelengs_input.csv")
      .then((r) => r.text())
      .then((csvStr) => Papa.parse(csvStr))
      .then(({ data }) => {
        const headers = data[0];
        const indexes = [
          "fq",
          "datetime",
          "from",
          "to",
          "locHouse",
          "locForest",
        ].reduce(
          (acc, name) => ({ ...acc, [name]: headers.indexOf(name) }),
          {}
        );
        const toJSNum = (str) => {
          const res = parseFloat(str.replace(",", "."));
          return typeof res === "number" && !_.isNaN(res) ? res : null;
        };
        const formatAbonent = (str) => {
          if (typeof str !== "string" || str.trim() === "") return null;
          return str[0].toUpperCase() + str.slice(1).toLowerCase();
        };
        return data.slice(1).reduce((acc, item) => {
          const fq = toJSNum(item[indexes.fq]);
          let time = item[indexes.datetime];
          const timeSplitted = time.split(":");
          if (timeSplitted.length === 3) {
            time = [timeSplitted[0], timeSplitted[1]].join(":");
          }
          const parsedTime = [
            "dd.MM.y H:mm",
            "dd-MM-y H:mm",
            "y-MM-dd H:mm",
          ].reduce((prev, cur) => {
            if (prev !== null && isValidDate(prev)) return prev;
            return parseDate(time, cur, new Date());
          }, null);
          if (!isValidDate(parsedTime)) {
            console.error("Time from csv not parsed", time);
          }
          time = parsedTime;

          const peleng = {
            time: add(time, { days: 22 }),
            from: formatAbonent(item[indexes.from]),
            to: formatAbonent(item[indexes.to]),
            locHouse: toJSNum(item[indexes.locHouse]),
            locForest: toJSNum(item[indexes.locForest]),
            uuid: uuidv4(),
          };

          const foundIndex = _.findIndex(acc, (x) => x.fq === fq);
          if (foundIndex !== -1) {
            acc[foundIndex] = {
              ...acc[foundIndex],
              pelengs: [...acc[foundIndex].pelengs, peleng],
            };
          } else {
            acc.push({
              fq: fq,
              pelengs: [peleng],
            });
          }
          return acc;
        }, []);
      })
      .then((parsed) => {
        setData(
          parsed
            .map((x) => ({
              ...x,
              pelengs: x.pelengs.sort((a, b) => b.time - a.time),
            }))
            .sort((x, y) => {
              return (
                getClosestPeleng(y.pelengs).time -
                getClosestPeleng(x.pelengs).time
              );
            })
        );
      });
  }, []);
  return data !== null ? (
    React.cloneElement(children, { data })
  ) : (
    <div>...завантажуємо данні пеленгації</div>
  );
};

export default DataLoader;

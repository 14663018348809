import formatDistance from "date-fns/formatDistance";
import ukLocale from "date-fns/locale/uk";
import React from "react";

class Distance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.getValue(),
    };
  }
  render() {
    return this.state.value;
  }
  getValue() {
    return formatDistance(this.props.value, new Date(), {
      locale: ukLocale,
      includeSeconds: true,
    });
  }
  setNextTimeout = () => {
    const now = new Date().valueOf();
    const timeout = now - this.props.value > 1000 * 60 ? 1000 * 60 : 1000 * 10;
    this.tid = setTimeout(() => {
      this.setState(
        {
          value: this.getValue(),
        },
        this.setNextTimeout
      );
    }, timeout);
  };
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.value !== this.props.value) {
      clearTimeout(this.tid);

      this.setState(
        {
          value: this.getValue(),
        },
        this.setNextTimeout
      );
    }
  }
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return (
      nextProps.value !== this.props.value ||
      nextState.value !== this.state.value
    );
  }

  componentDidMount() {
    this.setNextTimeout();
  }
  componentWillUnmount() {
    clearTimeout(this.tid);
  }
}

export default Distance;

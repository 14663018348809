import React from "react";
import { useEffect, useRef, useState } from "react";
import _ from "lodash";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
const __DEV__ = process.env.NODE_ENV === "development";

const Map = React.memo(({ objects, center, zoom }) => {
  const ref = useRef();
  const [ready, setReady] = useState(false);
  const payload = [...objects];
  if (center) {
    payload.push({ type: "center", value: center });
  }
  if (typeof zoom === "number") {
    payload.push({ type: "zoom", value: zoom });
  }

  useEffect(() => {
    const initialListener = (ev) => {
      let action = {};
      try {
        action = JSON.parse(ev.data);
      } catch (err) {}
      if (typeof action.type === "string" && action.type === "READY") {
        ref.current.contentWindow.postMessage(payload, "*");
        setReady(true);
      }
    };
    window.addEventListener("message", initialListener, false);
    return () => {
      window.removeEventListener("message", initialListener, false);
    };
  }, []);

  useEffect(() => {
    if (ready) {
      ref.current.contentWindow.postMessage(payload, "*");
    }
  }, [objects, center, zoom]);

  return (
    <div id="map">
      {!ready && (
        <div className="map__loader">
          <CircularProgress />
        </div>
      )}
      <iframe
        title={"VuhoMap"}
        ref={ref}
        width={"100%"}
        height={"100%"}
        style={{ backgroundColor: "black" }}
        src={
          "https://map.sapiense.com/engine.html"
          // __DEV__
          //   ? "http://127.0.0.1:8080/engine.html"
          //   : "https://map.sapiense.com/engine.html"
        }
        frameBorder="0"
        sandbox="allow-forms allow-scripts allow-same-origin allow-top-navigation	"
      />
    </div>
  );
}, _.isEqual);

export default Map;

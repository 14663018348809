import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  TextField,
} from "@mui/material";
import _ from "lodash";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import Locations from "./Locations";
import { v4 as uuidv4 } from "uuid";
import { useSelector } from "react-redux";
import { doc, setDoc, addDoc, collection } from "firebase/firestore";
import { db } from "../../firebase";

const checkValidity = (config) => {
  const validators = {
    ["beam_angle"]: (v) => {
      v = parseFloat(v);
      return 0 <= v && v <= 360;
    },
    ["nickname"]: (v) => v.length >= 1,
    ["locations"]: (v) =>
      _.every(v, ({ nickname, lat, lng, height }) => {
        if (
          _.some([nickname, lat, lng, height], (s) => {
            if (typeof s === "string") {
              return s.trim() === "";
            } else {
              return false;
            }
          })
        ) {
          return false;
        }

        return (
          nickname.length >= 1 &&
          parseFloat(lat) > 0 &&
          parseFloat(lng) > 0 &&
          !_.isNaN(parseFloat(height))
        );
      }),
  };
  return _.every(config, (value, key) =>
    validators[key] ? validators[key](value) : true
  );
};

const normalize = (table) => (values) =>
  _.mapValues(values, (v, k) => {
    return typeof table[k] === "function" ? table[k](v) : v;
  });

const HardwareCard = ({ add, config, onChangeSave, onAddSave }) => {
  const user_uid = useSelector(({ profile }) => profile.uid);
  const team = useSelector(({ profile }) => profile.team);
  if (add) {
    config = {
      color: "#000000",
      beam_angle: 1.5,
      nickname: "",
      // current_location,
      // beam_length,
      locations: [],
      team_id: team.id,
      created_by: user_uid,
      beam_length: 300,
      type: "pelengator",
    };
  }

  const [updated, setUpdated] = useState(config);

  useEffect(() => {
    !add && setUpdated(config);
  }, [config]);

  const {
    type,
    beam_angle,
    created_by,
    team_id,
    nickname,
    current_location,
    color,
    beam_length,
    locations,
  } = updated;

  const isChanged = _.isEqual(config, updated);
  // console.log(123, config, updated);
  const isValid = checkValidity(updated);

  const addNewLocation = () => {
    const newId = uuidv4();
    setUpdated({
      ...updated,
      locations: {
        [newId]: {
          id: newId,
          nickname: "",
          lat: "",
          lng: "",
          height: "",
          created_at: new Date().valueOf(),
          created_by: user_uid,
        },
        ...updated.locations,
      },
      current_location:
        updated.locations.length === 0 ? newId : updated.current_location,
    });
  };
  const setCurrentPoint = (id) => {
    setUpdated({
      ...updated,
      current_location: id,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const normalized = normalize({
      beam_angle: parseFloat,
      locations: (v) => {
        return _.mapValues(
          v,
          normalize({
            lat: parseFloat,
            lng: parseFloat,
            height: parseFloat,
          })
        );
      },
    })(updated);

    if (!add) {
      const ref = await setDoc(doc(db, "hardware", config.id), normalized, {
        merge: true,
      });
      console.log("edit result", ref);
    } else {
      const ref = await addDoc(collection(db, "hardware"), normalized);
      await setDoc(
        doc(db, "hardware", ref.id),
        { id: ref.id },
        { merge: true }
      );
      onAddSave();
    }

    //sendData
  };

  return (
    <form
      action="src/UI/Settings/HardwareCard#"
      onSubmit={handleSubmit}
      style={{ marginBottom: 20 }}
    >
      <Card variant="outlined">
        <CardContent>
          {/*<Typography variant="h6">Тип: {type}</Typography>*/}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/*<Typography variant="h5" sx={{ marginBottom: 2 }}>*/}
              {/*  {nickname}*/}
              {/*</Typography>*/}
              <div style={{ marginBottom: 10 }}>
                <SettingsField
                  label={"Кодова назва пеленгатору"}
                  value={nickname}
                  onChange={({ target }) => {
                    setUpdated({
                      ...updated,
                      nickname: target.value,
                    });
                  }}
                />
              </div>
              <div style={{ marginBottom: 10 }}>
                <SettingsField
                  label={"Рівень похибки (в градусах)"}
                  type={"number"}
                  value={beam_angle}
                  onChange={({ target }) => {
                    setUpdated({
                      ...updated,
                      beam_angle: target.value,
                    });
                  }}
                />
              </div>
              <div
                style={{
                  marginBottom: 10,
                }}
              >
                <SettingsField
                  label={"Колір"}
                  type={"color"}
                  width={65}
                  value={color}
                  onChange={({ target }) => {
                    setUpdated({
                      ...updated,
                      color: target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div>
              <div className="st-locations">
                <div style={{ marginBottom: "14px", textAlign: "right" }}>
                  <Button
                    onClick={addNewLocation}
                    variant={"outlined"}
                    size={"small"}
                  >
                    Додати локацію
                  </Button>
                </div>
                {_.isPlainObject(locations) && !_.isEmpty(locations) && (
                  <Locations
                    add={add}
                    locations={locations}
                    current_location={current_location}
                    setLocations={(locations) =>
                      setUpdated({
                        ...updated,
                        locations,
                      })
                    }
                    dbLocations={_.map(config.locations, "id")}
                    setCurrentPoint={setCurrentPoint}
                  />
                )}
                {_.isEmpty(locations) && (
                  <div className={"st-locations__should-add"}>
                    Додайте хоча б одну локацію, щоб активувати обладнання
                  </div>
                )}
              </div>
            </div>
          </div>
        </CardContent>
        {!isChanged && (
          <CardActions sx={{ backgroundColor: "#141e00" }}>
            <Button
              type="submit"
              style={{ marginLeft: "auto" }}
              variant="contained"
              form={`form_${nickname}`}
              onClick={handleSubmit}
              disabled={!isValid}
            >
              Зберегти
            </Button>
          </CardActions>
        )}
      </Card>
    </form>
  );
};

const SettingsField = (props) => {
  return (
    <TextField
      variant="outlined"
      size={"small"}
      InputLabelProps={{ shrink: true }}
      sx={{ width: props.width || "auto", marginBottom: 1 }}
      type={"text"}
      {...props}
    />
  );
};

export default HardwareCard;

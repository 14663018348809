import { Link, Outlet, useLocation, useParams } from "react-router-dom";
import { useAuth } from "../hooks";
import Authenticator from "./Authenticator";
import SettingsIcon from "@mui/icons-material/Settings";
import { useSelector } from "react-redux";

const Wrapper = () => {
  const authorised = useAuth();
  // const authorised = true;

  return (
    <div className={"app"}>
      {authorised && (
        <>
          <div className="app__top">
            <ProfilePanel />
          </div>
          <div className="app__content">
            <Outlet />
          </div>
        </>
      )}
      {!authorised && <Authenticator />}
    </div>
  );
};

const ProfilePanel = () => {
  const { pathname } = useLocation();
  const { nickname, team } = useSelector(({ profile }) => profile);

  return (
    <div className={"profile"}>
      <Link to={pathname === "/settings" ? "/" : "/settings"}>
        {nickname}
        <SettingsIcon fontSize={"small"} />
      </Link>
      , <b>{team.nickname}</b>
    </div>
  );
};

export default Wrapper;
